import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import Axios from 'axios';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import "./index.scss";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Revista = () => {
  const { addToast } = useToasts();
  const refCapa = React.useRef(null);
  const refArquivo = React.useRef(null);
  const [ revista, setRevista ] = useState([]);
  const [ revistaSelect, setRevistaSelect ] = useState([]);
  const [ mesSelect, setMesSelect ] = useState([]);
  const [ anoSelect, setAnoSelect ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaTitulo, setBuscaTitulo ] = useState();
  const [ buscaMes, setBuscaMes ] = useState();
  const [ buscaAno, setBuscaAno ] = useState();
  const [ buscaVolume, setBuscaVolume ] = useState();
  const [ buscaNumero, setBuscaNumero ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formRevista, setFormRevista ] = useState({});
  const [ acao, setAcao ] = useState();

  function fecharModal(){
    setModal(false);
  }

  const data = revista;
  const columns = [
    {
      name: <th>Capa</th>,
      width: '12%',
      center: true,
      cell: row => <img src={"https://www.stabsul.info/motorStab/assets/img/revista/capa/"+row.capa} width="100px" />,
    },
    {
      name: <th>Nome</th>,
      selector: 'descricao',
      sortable: true,
      wrap: true,
      width: '10%',
    },
    {
      name: <th>Título</th>,
      selector: 'titulo',
      sortable: true,
      wrap: true,
      width: '20%',
    },
    {
      name: <th>Mês</th>,
      selector: 'mes',
      width: '15%',
      sortable: true,
      wrap: true,
    },
    {
      name: <th>Ano</th>,
      selector: 'ano',
      width: '6%',
      sortable: true,
    },
    {
      name: <th>Volume</th>,
      width: '8%',
      sortable: true,
      selector: 'volume',
      center: 'true'
    }, 
    {
      name: <th>Número</th>,
      width: '8%',
      sortable: true,
      selector: 'numero',
      center: 'true'
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <div class="btn-group">
          <button className="btn btn-info btn-sm dropdown-toggle mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mais</button>
            <div class="dropdown-menu">
              <Link to={"/sistema/revista/financeiro"} class="dropdown-item" onClick={e => { sessaoRevista(row.id) }}>Lançamentos</Link>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item">Enviar e-mail</a>
              <a class="dropdown-item">Etiquetas</a>
            </div>
        </div>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>

    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
    getRevistaSelect();
    getMesSelect();
    getAnoSelect();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("revistaedicao/listar").then(rps => {
      
      setRevista(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function getRevistaSelect() {
    Api.get("revista/listar").then(rps => {
      var reg = rps.data.obj;
      var nReg = [];
      nReg[nReg.length] = {value: '', label: 'Selecione...'};
      reg.forEach(e => {
        nReg[nReg.length] = {value: e.id, label: e.descricao};
      })
      setRevistaSelect(nReg);
    })
  }

  function getMesSelect() {
    Api.get("mes/listar").then(rps => {
      var reg = rps.data.obj;
      var nReg = [];
      nReg[nReg.length] = {value: '', label: 'Selecione...'};
      reg.forEach(e => {
        nReg[nReg.length] = {value: e.id, label: e.descricao};
      })
      setMesSelect(nReg);
    })
  }

  function getAnoSelect() {
    Api.get("ano/listar").then(rps => {
      var reg = rps.data.obj;
      var nReg = [];
      nReg[nReg.length] = {value: '', label: 'Selecione...'};
      reg.forEach(e => {
        nReg[nReg.length] = {value: e.id, label: e.id};
      })
      setAnoSelect(nReg);
    })
  }

  async function handleCapaChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formRevista.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('revistaedicao/capa', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormRevista({...formRevista, capa: response.data.url_imagem});
      console.log(response.data.url_imagem);
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  async function handleArquivoChange(e) {
    const form_data = new FormData();
    form_data.append('capa', e.target.files[0]);
    form_data.append('id', formRevista.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('revistaedicao/arquivo_pdf', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormRevista({...formRevista, pdf: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  function adicionar(){
    setFormRevista({});
    setModal(true);
    setAcao('Adicionar');
  }

  function sessaoRevista($id) {
    sessionStorage.setItem('revista', $id);
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setFormRevista(ca);
    setModal(true);
    setAcao('Alterar');            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('revistaedicao/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);
    Api.post('revistaedicao/adicionar', formRevista).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        
        if (acao != 'Alterar') {
          setFormRevista({...formRevista, id: rps.data.id});
        }
        setAcao('Alterar');
        //setModal(false);
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        titulo: buscaTitulo,
        mes: buscaMes,
        ano: buscaAno,
        volume: buscaVolume,
        numero: buscaNumero,
      };
    
      Api.post('revistaedicao/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setRevista(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Revista</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-2">
                  <label>Nome</label>
                  <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col">
                  <label>Título</label>
                  <input type="text" value={buscaTitulo} onChange={e => {setBuscaTitulo(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <label>Mês</label>
                  <input type="text" value={buscaMes} onChange={e => {setBuscaMes(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-1">
                  <label>Ano</label>
                  <input type="text" value={buscaAno} onChange={e => {setBuscaAno(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-1">
                  <label>Volume</label>
                  <input type="text" value={buscaVolume} onChange={e => {setBuscaVolume(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-1">
                  <label>Número</label>
                  <input type="text" value={buscaNumero} onChange={e => {setBuscaNumero(e.target.value)}} className="form-control" />
                </div>


                
                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Revistas"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Revista</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Revista</label>
          <Select 
            options={revistaSelect}
            placeholder="Selecione..." 
            defaultValue={revistaSelect.find(
              x => x.value === formRevista.revista_id
            )}
            onChange={(e) => { setFormRevista({...formRevista, revista_id: e.value}) }}/>
        </div>

        <div className="form-group col-md-3">
          <label>Mês</label>
          <Select 
            options={mesSelect}
            placeholder="Selecione..." 
            defaultValue={mesSelect.find(
              x => x.value === formRevista.mes_id
            )}
            onChange={(e) => { setFormRevista({...formRevista, mes_id: e.value}) }}/>
        </div>

        <div className="form-group col-md-2">
          <label>Ano</label>
          <Select 
            options={anoSelect}
            placeholder="Selecione..." 
            defaultValue={anoSelect.find(
              x => x.value === formRevista.ano
            )}
            onChange={(e) => { setFormRevista({...formRevista, ano: e.value}) }}/>
        </div>

        <div className="form-group col-md-1">
          <label>Volume</label>
          <input type="text" value={formRevista?.volume} onChange={e => {setFormRevista({...formRevista, volume: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-1">
          <label>Número</label>
          <input type="text" value={formRevista?.numero} onChange={e => {setFormRevista({...formRevista, numero: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-1">
          <label>N° Páginas</label>
          <input type="text" value={formRevista?.n_paginas} onChange={e => {setFormRevista({...formRevista, n_paginas: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-1">
          <label>Publicada</label>
          <select className="form-control" value={formRevista?.finalizado} onChange={e => {setFormRevista({...formRevista, finalizado: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="S">Sim</option>    
            <option value="N">Não</option>    
          </select>
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Título</label>
          <input type="text" value={formRevista?.titulo} onChange={e => {setFormRevista({...formRevista, titulo: e.target.value})}} className="form-control" />
        </div>
      </div>
      
      { acao == 'Alterar' && <>
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Imagem (Capa)</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
              onClick={e => {refCapa.current.click();} } >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formRevista?.capa ? "https://www.stabsul.info/motorStab/assets/img/revista/capa/"+formRevista?.capa : "/img/revista/capa/no-image.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input type="file" ref={refCapa} name="imagemLogo" onChange={(e) => handleCapaChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
            </div>
          </div>
        </div>

        <div className="form-group col-md-6">
          <label>Arquivo (PDF) {formRevista?.pdf ? <a className="label label-success label-inline mr-2" href={"https://www.stabsul.info/motorStab/assets/img/revista/"+formRevista.pdf} target="_blank">Clique aqui para abrir o arquivo</a> : ""}</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
              onClick={e => {refArquivo.current.click();} } >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formRevista?.pdf ? "/img/revista/artigo/arquivo_pdf.jpg" : "/img/revista/capa/nao_disponivel.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar arquivo</span>
              </div>
              <input type="file" ref={refArquivo} name="imagemLogo" onChange={(e) => handleArquivoChange(e)} accept=".pdf" className="classeInputImage" />
            </div>
          </div>
        </div>
      </div>
      </> }

      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Revista);                  