import React, { useState, useEffect, useRef } from 'react';
import Api from '../../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import ReactToPrint from "react-to-print";

import "./index.scss";


const Etiquetas = () => {
  const { addToast } = useToasts();
  const [ relatorio, setRelatorio ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const printRef = useRef();

  useEffect(() => {
    buscar();
  }, []);

  function buscar() {
    setCaProcessa(true); 
    var data = {
      tipo_cadastro: sessionStorage.getItem('sessionBuscaTipoCadastro') !== 'null' && sessionStorage.getItem('sessionBuscaTipoCadastro') !== 'undefined' ? sessionStorage.getItem('sessionBuscaTipoCadastro') : '',
      revista: sessionStorage.getItem('sessionBuscaRevista') !== 'null' && sessionStorage.getItem('sessionBuscaRevista') !== 'undefined' ? sessionStorage.getItem('sessionBuscaRevista') : '',
      anuidade: sessionStorage.getItem('sessionBuscaAnuidade') !== 'null' && sessionStorage.getItem('sessionBuscaAnuidade') !== 'undefined' ? sessionStorage.getItem('sessionBuscaAnuidade') : '',
      tipo_pessoa: sessionStorage.getItem('sessionBuscaTipoPessoa') !== 'null' && sessionStorage.getItem('sessionBuscaTipoPessoa') !== 'undefined' ? sessionStorage.getItem('sessionBuscaTipoPessoa') : '',
      tipo_socio: sessionStorage.getItem('sessionBuscaTipoSocio') !== 'null' && sessionStorage.getItem('sessionBuscaTipoSocio') !== 'undefined' ? sessionStorage.getItem('sessionBuscaTipoSocio') : '',
      area_atuacao: sessionStorage.getItem('sessionBuscaAreaAtuacao') !== 'null' && sessionStorage.getItem('sessionBuscaAreaAtuacao') !== 'undefined' ? sessionStorage.getItem('sessionBuscaAreaAtuacao') : '',
      regional: sessionStorage.getItem('sessionBuscaRegional') !== 'null' && sessionStorage.getItem('sessionBuscaRegional') !== 'undefined' ? sessionStorage.getItem('sessionBuscaRegional') : '',
    };

    Api.post("relatorios/relatorioEtiquetas", data).then(rps => {
      
      if(rps.data.status==false){
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        setRelatorio(rps.data.obj);
      }
    setCaProcessa(false); 
    })
  }
    
    return (
        <>

              <ReactToPrint
                trigger={() => <button className="btn btn-md btn-secondary mt-6"><i className="fas fa-print" />Imprimir Relatório</button>}
                content={() => printRef.current}
              />

                  <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 
                  
                  <div ref={printRef}>

                  { relatorio.map( (item ) => { 
                      
                      return (
                        <>
                    <table width="360px" border="0" height="102px" cellpadding="0" cellspacing="0">
                      <tr>
                        <td width="360px" height="20px">
                          <font face="arial" size="2">
                            <b>{item.dp_nome_rsociao}</b>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <font face="arial" size="1">{item.departamento != null ? item.departamento : "-"} </font>
                          </td>
                        </tr>
                      <tr>
                        <td>
                          <font face="arial" size="1">{item.endereco}, {item.numero} {item.complemento}</font>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <font face="arial" size="1">{item.cidade}, {item.uf}  -  BRASIL</font>
                        </td>
                      </tr>
                      <tr>
                        <td height="20px"><font face="arial" size="2">
                          <p align="right"><b>CEP: {item.cep}</b></p></font>
                        </td>
                      </tr>
                    </table>
                    </>
                      )
                  })}
                  </div>                  
                  </LoadingOverlay> 
        </>
    );

}

export default withRouter(Etiquetas);                  