import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import Axios from 'axios';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import { TagsInput } from "react-tag-input-component";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Artigo = () => {
  const { addToast } = useToasts();
  const refArtigo = React.useRef(null);
  const [ artigos, setArtigos ] = useState([]);
  const [ artigoSelect, setArtigoSelect ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaRevista, setBuscaRevista ] = useState();
  const [ buscaTitulo, setBuscaTitulo ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formArtigo, setFormArtigo ] = useState({});
  const [ acao, setAcao ] = useState();
  const [ tags, setTags] = useState([]);
  const [ autores, setAutores] = useState([]);

  function fecharModal(){
    setModal(false);
  }

  const data = artigos;
  const columns = [
    {
      name: <th>Capa</th>,
      width: '12%',
      center: true,
      cell: row => <img src={"https://www.stabsul.info/motorStab/assets/img/revista/capa/"+row.capa} width="100px" />,
    },
    {
      name: <th>Revista</th>,
      selector: 'descricao',
      width: '15%',
      sortable: true,
    },
    {
      name: <th>Título</th>,
      selector: 'titulo',
      sortable: true,
      wrap: true,
      width: '57%',
    },
    
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>
    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
    getTipoLojaSelect();
  }, []);

  function getTipoLojaSelect() {
    Api.get("revistaedicao/listar").then(rps => {
      var tipo = rps.data.obj;
      var nTipo = [];
      nTipo[nTipo.length] = {value: '', label: 'Selecione...'};
      tipo.forEach(e => {
        nTipo[nTipo.length] = {value: e.id, label: <span><img src={"https://www.stabsul.info/motorStab/assets/img/revista/capa/"+e.capa} width="40px" /> {e.descricao} - {e.titulo}</span>};
      })
      setArtigoSelect(nTipo);
    })
  }

  function getListar() { 
    setCaProcessa(true); 
    Api.get("artigo/listar").then(rps => {
      
      setArtigos(rps.data.obj);
      console.log(rps.data.obj);

      setCaProcessa(false);
    })
  }
  
  function adicionar(){
    setFormArtigo({});
    setModal(true);
    setAcao('Adicionar');
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setFormArtigo(ca); 

    setTags(JSON.parse(ca.palavras));
    setAutores(JSON.parse(ca.autores));
    setModal(true); 
    setAcao('Alterar');            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('artigo/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);
    formArtigo.palavras = JSON.stringify(tags);
    formArtigo.autores = JSON.stringify(autores);

    Api.post('artigo/adicionar', formArtigo).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        //setModal(false);
        if (rps.data.artigo !== '' || rps.data.artigo !== undefined) {
          setFormArtigo({...formArtigo, id: rps.data.artigo});
        }
        getListar();
        setAcao('Alterar');
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  async function handleArquivoChange(e) {
    const form_data = new FormData();
    form_data.append('capa', e.target.files[0]);
    form_data.append('id', formArtigo.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('artigo/arquivo_pdf', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormArtigo({...formArtigo, arquivo: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        revista: buscaRevista,
        titulo: buscaTitulo,
        status: buscaStatus,
      };
    
      Api.post('artigo/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setArtigos(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Artigo</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-4">
                  <label>Revista</label>
                  <Select 
                    options={artigoSelect}
                    placeholder="Selecione..." 
                    defaultValue={artigoSelect.find(
                      x => x.value === formArtigo?.id_revista
                    )}
                    onChange={(e) => { setBuscaRevista(e.value) }}/>
                </div>

                <div className="form-group col-md-4">
                  <label>Título</label>
                  <input type="text" value={buscaTitulo} onChange={e => {setBuscaTitulo(e.target.value)}} className="form-control" />
                </div>

                
                <div className="form-group col">
                  <label>Status</label>
                  <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Ativo</option>    
                      <option value="N">Desativado</option>    
                    </select>
                </div>

                <div className="col form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Artigos"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Artigo</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-10">
          <label>Título</label>
          <input type="text" value={formArtigo?.titulo} onChange={e => {setFormArtigo({...formArtigo, titulo: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Status</label>
          <select className="form-control" value={formArtigo?.ativo} onChange={e => {setFormArtigo({...formArtigo, ativo: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="S">Ativo</option>    
            <option value="N">Desativado</option>    
          </select>
        </div>
      </div>
      
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Revista</label>
          <Select 
            options={artigoSelect}
            placeholder="Selecione..." 
            defaultValue={artigoSelect.find(
              x => x.value === formArtigo?.id_revista
            )}
            onChange={(e) => { setFormArtigo({...formArtigo, id_revista: e.value}) }}/>
        </div>

      </div>

      { acao == 'Alterar' && <>
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Arquivo (PDF) {formArtigo?.arquivo ? <a className="label label-success label-inline mr-2" href={"https://www.stabsul.info/motorStab/assets/img/revista/artigo/"+formArtigo.arquivo} target="_blank">Clique aqui para abrir o arquivo</a> : ""}</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
              onClick={e => {refArtigo.current.click();} } >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formArtigo?.arquivo ? "/img/revista/artigo/arquivo_pdf.jpg" : "/img/revista/capa/nao_disponivel.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar arquivo</span>
              </div>
              <input type="file" ref={refArtigo} name="imagemLogo" onChange={(e) => handleArquivoChange(e)} accept=".pdf" className="classeInputImage" />
            </div>
          </div>
        </div>

        <div className="form-group col-md-6">
          <label>Palavra Chave</label>
          <TagsInput
            value={tags}
            onChange={setTags}
            name="palavra"
            placeHolder="Digite a palavra chave"
          />
          <label>Autores</label>
          <TagsInput
            value={autores}
            onChange={setAutores}
            name="palavra"
            placeHolder="Digite o(s) nome(s) do(s) autor(es)."
          />
        </div>
      </div>
      </>}  
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Artigo);                  