import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import Axios from 'axios';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Evento = () => {
  const { addToast } = useToasts();
  const refProgramacao = React.useRef(null);
  const [ evento, setEventos ] = useState([]);
  const [ estadoSelect, setEstadoSelect ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaCidade, setBuscaCidade ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaUF, setBuscaUF ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formEvento, setFormEvento ] = useState({});
  const [ dataInicialBusca, setDataInicialBusca ] = useState();
  const [ dataFinalBusca, setDataFinalBusca ] = useState();
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState();
  const [ acao, setAcao ] = useState();

  function fecharModal(){
    setModal(false);
  }

  const data = evento;
  const columns = [
    {
      name: <th>Capa</th>,
      width: '12%',
      center: true,
      cell: row => <img src={row.programacao ? "https://www.stabsul.info/motorStab/assets/img/eventos/"+row.programacao : "/img/eventos/no-image.jpg"} width="100px" />,
    },
    {
      name: <th>Nome</th>,
      selector: 'titulo',
      sortable: true,
      wrap: true,
      width: '27%',
    },
    {
      name: <th>Cidade</th>,
      selector: 'cidade',
      width: '12%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Estado</th>,
      selector: 'estado',
      width: '6%',
      sortable: true,
    },
    {
      name: <th>Inscritos</th>,
      selector: 'inscritos',
      width: '6%',
      sortable: true,
    },
    {
      name: <th>Dt. Inicial</th>,
      width: '8%',
      sortable: true,
      selector: 'data_criacao',
      center: 'true'
    }, 
    {
      name: <th>Dt. Final</th>,
      width: '8%',
      sortable: true,
      selector: 'data_atualizacao',
      center: 'true'
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <div class="btn-group">
          <button className="btn btn-info btn-sm dropdown-toggle mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mais</button>
            <div class="dropdown-menu">
              <Link to={"/sistema/evento/inscricoes"} class="dropdown-item" onClick={e => { sessaoEvento(row.id) }}>Inscrições</Link>
              <Link to={"/sistema/evento/palestra"} class="dropdown-item" onClick={e => { sessaoEvento(row.id) }}>Palestras</Link>
              <Link to={"/sistema/evento/financeiro"} class="dropdown-item" onClick={e => { sessaoEvento(row.id) }}>Lançamentos</Link>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item">Enviar e-mail</a>
              <a class="dropdown-item">Etiquetas</a>
            </div>
        </div>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>
    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
    getEstadoSelect();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("evento/listar").then(rps => {
      
      setEventos(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function sessaoEvento($id) {
    sessionStorage.setItem('evento', $id);
  }

  function getEstadoSelect() {
    Api.get("estado/listar").then(rps => {
      var cat = rps.data.obj;
      var nCat = [];
      nCat[nCat.length] = {value: '', label: 'Selecione...'};
      cat.forEach(e => {
        nCat[nCat.length] = {value: e.uf, label: e.est_nome};
      })
      setEstadoSelect(nCat);
    })
  }
  
  function adicionar(){
    setFormEvento({});
    setDataInicial();
    setDataFinal();
    setModal(true);
    setAcao('Adicionar');
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setDataInicial(new Date(ca.dt_inicial + ' 12:00:00'));
    setDataFinal(new Date(ca.dt_final + ' 12:00:00'));

    setFormEvento(ca);
    setModal(true); 
    setAcao('Alterar');            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('evento/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);

    formEvento.dt_inicial = dataInicial;
    formEvento.dt_final = dataFinal;

    Api.post('evento/adicionar', formEvento).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        //setModal(false);
        if (acao != 'Alterar') {
          setFormEvento({...formEvento, id: rps.data.id});
        }
        getListar();
        setAcao('Alterar');
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  async function handleProgramacaoChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formEvento.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('evento/capa', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormEvento({...formEvento, programacao: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    filtrar();
    setModal(false);
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        cidade: buscaCidade,
        uf: buscaUF,
        dt_inicial: dataInicialBusca,
        dt_final: dataFinalBusca,
      };
    
      Api.post('evento/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setEventos(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Evento</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-3">
                  <label>Nome</label>
                  <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-3">
                  <label>Cidade</label>
                  <input type="text" value={buscaCidade} onChange={e => {setBuscaCidade(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <label>Estado</label>
                  <Select 
                    options={estadoSelect}
                    placeholder="Selecione..." 
                    defaultValue={estadoSelect.find(
                      x => x.value === buscaUF
                    )}
                    onChange={(e) => { setBuscaUF(e.value) }}/>
                </div>

                <div className="form-group col">
                  <label>Dt. Inicial</label>
                  <div className="input-group">
                      <DatePicker 
                        name="dt_inicio"
                        onChange={setDataInicialBusca}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicialBusca}
                        dateFormat="dd/MM/y"
                        startDate={dataInicialBusca}
                        endDate={dataFinalBusca}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                </div>

                <div className="form-group col">
                  <label>Dt. Final</label>
                  <div className="input-group">
                      <DatePicker 
                        name="dt_final"
                        onChange={setDataFinalBusca}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinalBusca}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicialBusca}
                        endDate={dataFinalBusca}
                        minDate={dataInicialBusca} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                </div>

                  <div className="col form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Eventos"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Evento</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Nome do evento</label>
          <input type="text" value={formEvento?.titulo} onChange={e => {setFormEvento({...formEvento, titulo: e.target.value})}} className="form-control" />
        </div>
      </div>
      
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-2">
          <label>Tipo</label>
            <select className="form-control" value={formEvento?.tipo} onChange={e => {setFormEvento({...formEvento, tipo: e.target.value})}}>
              <option value="" selected>Selecione</option>    
              <option value="Evento">Evento</option>    
              <option value="EventoStab">EventoStab</option>    
              <option value="Webinar">Webinar</option>    
            </select>
        </div>

        <div className="form-group col-md-2">
          <label>Estado</label>
          <Select 
            options={estadoSelect}
            placeholder="Selecione..." 
            defaultValue={estadoSelect.find(
              x => x.value === formEvento.estado
            )}
            onChange={(e) => { setFormEvento({...formEvento, estado: e.value}) }}/>
        </div>

        <div className="form-group col-md-5">
          <label>Cidade</label>
          <input type="text" value={formEvento?.cidade} onChange={e => {setFormEvento({...formEvento, cidade: e.target.value})}} className="form-control" />
        </div>

      </div>

      <div className="row ml-5 mr-5">
          { formEvento?.tipo === 'Webinar' ?
          <>
          <div class="form-group col-md-6">
            <label>Identificador do vídeo</label>
            <input type="text" value={formEvento?.descricao} onChange={e => {setFormEvento({...formEvento, descricao: e.target.value})}} className="form-control" />
          </div>
          <div class="form-group col-md-6">
            <label className="text-danger">Inserir apenas o identificador do Youtube</label><br />
            <label>Ex: https://www.youtube.com/watch?v=<span className="text-danger">C2YdKzcE5p8</span></label>     
          </div>
          </>
          :
          <div class="form-group col-md-12">
            <label>Descrição</label>
            <CKEditor
              editor={ ClassicEditor }
              data={formEvento?.descricao}
              onChange={( event, editor ) => { setFormEvento({...formEvento, descricao: editor.getData()}) }}            
            /> 
          </div>
          }
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-2">
          <label>Data Inicial</label>
          <DatePicker 
            name="dt_inicio"
            onChange={setDataInicial}
            isClearable
            selectsStart
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa" 
            selected ={dataInicial}
            dateFormat="dd/MM/y"
            startDate={dataInicial}
            endDate={dataFinal}
            customInput={
              <InputMask mask="99/99/9999" />}/>
        </div>

        <div className="form-group col-md-2">
          <label>Data Final</label>
          <DatePicker 
            name="dt_final"
            onChange={setDataFinal}
            isClearable
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa" 
            selected ={dataFinal}
            dateFormat="dd/MM/y"
            selectsEnd
            startDate={dataInicial}
            endDate={dataFinal}
            minDate={dataInicial} 
            customInput={
              <InputMask mask="99/99/9999" />}/>
        </div>

        <div className="form-group col-md-2">
        </div>

        <div className="form-group col-md-3">
          <label>Capacidade</label>
          <input type="text" value={formEvento?.capacidade} onChange={e => {setFormEvento({...formEvento, capacidade: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Inscritos</label>
          <input type="text" value={formEvento?.inscritos} className="form-control" disabled />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Valor Sócio</label>
          <input type="text" value={formEvento?.socio} onChange={e => {setFormEvento({...formEvento, socio: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Valor Não Sócio</label>
          <input type="text" value={formEvento?.nao_socio} onChange={e => {setFormEvento({...formEvento, nao_socio: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Valor Sócio Estudante</label>
          <input type="text" value={formEvento?.estudante} onChange={e => {setFormEvento({...formEvento, estudante: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Valor Sócio Não Estudante</label>
          <input type="text" value={formEvento?.nao_socio_estudante} onChange={e => {setFormEvento({...formEvento, nao_socio_estudante: e.target.value})}} className="form-control" />
        </div>
      </div> 

      { acao == 'Alterar' && <>
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Imagem (Capa)</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
              onClick={e => {refProgramacao.current.click();} } >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formEvento?.programacao ? "https://www.stabsul.info/motorStab/assets/img/eventos/"+formEvento?.programacao : "/img/eventos/no-image.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input type="file" ref={refProgramacao} name="imagemLogo" onChange={(e) => handleProgramacaoChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
            </div>
          </div>
        </div>
      </div>
      </>}  
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Evento);                  