import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";
import Header from '../../layout/Header';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import { Modal, Button, Row } from "react-bootstrap";

import DataTable from 'react-data-table-component';
import LoadingOverlay from 'react-loading-overlay';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Inscricoes = () => {

  function formata(val) {
    val = parseFloat(val);
    if (val > 0) {
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }
    return '0,00';
  }

  const { addToast } = useToasts();
  const [ oportunidades, setOportunidades ] = useState([]);
  const [ evento, setEvento ] = useState([]);
  const [ eventos, setEventos ] = useState([]);
  const [ itens, setItens ] = useState([]);
  const [ valorPedido_s, setValorPedido_s ] = useState();
  const [ valorPedido_ns, setValorPedido_ns ] = useState();
  const [ desconto, setDesconto ] = useState();
  const [ status, setStatus ] = useState();
  const [ nome, setNome ] = useState();
  const [ dataEnvioInicial, setDataEnvioInicial ] = useState();
  const [ dataEnvioFinal, setDataEnvioFinal ] = useState();
  const [ cotacao, setCotacao ] = useState([]);
  const [ pessoa, setPessoa ] = useState([]);
  const [ exibir, setExibir ] = useState(false);
  const [ emailEncaminhar, setEmailEncaminhar ] = useState();
  const [ modal, setModal ] = useState(false);
    
  const [ caProcessa, setCaProcessa ] = useState(false);

  const data = oportunidades;
  const columns = [
    {
      name: <th>Data</th>,
      selector: 'data_insc',
      sortable: true,
      width: '20%',
    },
    {
      name: <th>Nome</th>,
      selector: 'dp_nome_rsociao',
      width: '30%',
      sortable: true,
     },
     {
      name: <th>Título</th>,
      selector: 'titulo',
      width: '24%',
      sortable: true,
     },
     {
      name: <th>Tipo</th>,
      selector: 'tipo_cadastro',
      width: '13%',
      sortable: true,
      center: true,
      cell: row => { 
        return <>
            {row.tipo_cadastro === "Cadastro" && <>          
              <span className="label label-info label-inline mr-2">Cadastro</span>
            </>}
            {row.tipo_cadastro === "Sócio" && <>          
              <span className="label label-success label-inline mr-2">Sócio</span>
            </>}
            {row.tipo_cadastro === "Desativado" && <>          
              <span className="label label-danger label-inline mr-2">Desativado</span>
            </>}
            {row.tipo_cadastro === "Inativo" && <>          
              <span className="label label-secondary label-inline mr-2">Inativo</span>
            </>}
          </>
        }
     },
    {
      name: <th>Status</th>,
      selector: 'status',
      width: '13%',
      sortable: true,
      center: true,
      cell: row => { 
        return <>
            {row.status === "C" && <>          
              <span className="label label-info label-inline mr-2">Confirmada</span>
            </>}
            {row.status === "X" && <>          
              <span className="label label-danger label-inline mr-2">Cancelado</span>
            </>}
            {row.status === "N" && <>          
              <span className="label label-success label-inline mr-2">Nova</span>
            </>}
            {row.status === "E" && <>          
              <span className="label label-warning label-inline mr-2">Espera</span>
            </>}
          </>
        }
    },
  ];

  useEffect(() => {
    getListar();
    getEventos();
  }, []);

  function getListar() {
    Api.get("eventoinscricoes/listar").then(rps => {    
      setOportunidades(rps.data.obj);
    })
  }

  function getEventos() {
    Api.get("eventoinscricoes/listarevento").then(rps => {    
      setEventos(rps.data.obj);
    })
  }

  function fecharModal(){
    setModal(false);
  }

  function apagarCotacao($id, $acao) {
    var data = {
      idCotacao: $id,
      apagadaMotivo: $acao,
      apagada: 'S',
      usuario: localStorage.getItem('id_usuario')
    }
   
    Api.post("cotacoes/cotacaoApagar", data).then(rps => {
      Swal.fire({
        title: "Informação!",
        icon: "info",
        html: rps.data.mensagem,
        showCloseButton: true,
        showCancelButton: false,
    });
    })
    getListar();
  }

  function pesquisar() {
    setExibir(false);
      var data = {
        dtInicial: dataEnvioInicial,
        dtFinal: dataEnvioFinal,
        status: status,
        nome: nome,
        evento: evento,
      }

      Api.post("eventoinscricoes/filtrar", data).then(rps => {
        setOportunidades();
        if(rps.data.status==false){
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: rps.data.mensagem,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setOportunidades(rps.data.obj);
        }
      setCaProcessa(false); 
      })
  }

  function abrirCotacao(id, id_cadastro, id_evento) {  
    var data = {
      id: id,
      pessoa: id_cadastro,
      evento: id_evento
    } 
    sessionStorage.setItem('cotacao', id);

    setCaProcessa(true);
    Api.post('eventoinscricoes/listar_ins', data).then(rps => {
      setItens(rps.data.obj);
      setValorPedido_s(rps.data.valorPedido_s);
      setDesconto(rps.data.desconto);
      setValorPedido_ns(rps.data.valorPedido_ns);
    })

    Api.post('cadastros/listar_id', data).then(rps => {
      setPessoa(rps.data.obj);

      setCaProcessa(false);
    })
    setExibir(true);
  }

  async function enviar() {

    const response = await Api.post('cotacoes/simular', {
      enviarEmail: "Sim",
      atualizaCotacao: "Sim",
      cotacao: cotacao.id,
      pacote: cotacao.idProgramacao,
      dtInicial: cotacao.checkin,
      dtFinal: cotacao.checkout,
      adulto: cotacao.adultos,
      crianca12: cotacao.chd_12,
      crianca14: cotacao.chd_14,
      pet: cotacao.pet,
      nome: pessoa.nome,
      email: pessoa.email,
      diaria: cotacao.diarias,
      resposta: cotacao.resposta,
      enviarApartamento: cotacao.enviarApartamento,
      enviarSuite: cotacao.enviarSuite,
      enviarLuxo: cotacao.enviarLuxo,
      enviarFamilia: cotacao.enviarFamilia,
      usuario: localStorage.getItem('id_usuario')
    });

    if(response.data.status==true){
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
        setCotacao('');
        setPessoa('');
        setExibir(false);
    } else {
      Swal.fire({
        title: "Erro!",
        icon: "error",
        html: response.data.erro,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
    pesquisar();   
  }

  async function encaminhar() {
    pesquisar();
    setModal(false);   
  }
  
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2 mt-3">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Inscrições em Eventos</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>


        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div class="card card-custom gutter-b">
                  <div class="card-body">
                    <div className="row">
                      <div className="col-md-3 form-group">
                        <label>Data de inscrição de</label>
                          <DatePicker 
                            name="dt_inicio"
                            onChange={setDataEnvioInicial}
                            isClearable
                            selectsStart
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataEnvioInicial}
                            dateFormat="dd/MM/y"
                            startDate={dataEnvioInicial}
                            endDate={dataEnvioFinal}
                            customInput={
                              <InputMask mask="99/99/9999" />}/>      
                      </div>
                      <div className="col-md-3 form-group">
                        <label>Data de inscrição até</label>
                          <DatePicker 
                            name="dt_final"
                            onChange={setDataEnvioFinal}
                            isClearable
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataEnvioFinal}
                            dateFormat="dd/MM/y"
                            selectsEnd
                            startDate={dataEnvioInicial}
                            endDate={dataEnvioFinal}
                            minDate={dataEnvioInicial} 
                            customInput={
                              <InputMask mask="99/99/9999" />}/>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Status</label>
                        <select className="form-control" value={status} onChange={e => {setStatus(e.target.value)}}>
                          <option value="" selected>Selecione</option>    
                          <option value="Cancelado">Cancelado</option>
                          <option value="Confirmado">Confirmado</option>    
                          <option value="Espera">Espera</option>
                          <option value="Nova">Nova</option>    
                        </select>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Nome</label>
                          <input className="form-control" 
                                 placeholder="Pesquise pelo nome ou por parte do nome"
                                 value={nome} 
                                 onChange={e => {setNome(e.target.value)}} />
                      </div>
                    </div>

                    <div className="row">                        
                        <div className="col-md-12 form-group">
                            <label>Evento</label>
                            <select className="form-control" value={evento} onChange={e => {setEvento(e.target.value)}}>
                                <option value="">Selecione...</option>
                                {eventos.map((item) =>{ return(
                                <option value={item.id}>{item.titulo}</option>
                            )})}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-right">
                        <button className="btn btn-primary" onClick={e=>{pesquisar()}}><i class="flaticon2-search-1" />Pesquisar</button>
                      </div>
                    </div>
                    
                    <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 
                    <DataTable
                      title="Lista de Inscrições"
                      columns={columns}
                      data={data}
                      onRowClicked={data => { abrirCotacao(data.id, data.id_cadastro, data.id_evento)}}
                      striped="true"
                      pagination="true"
                    />
                    </LoadingOverlay>

                  </div>
                </div>
              </div>    

              {exibir === true &&              
              <div className="col-md-4">
                <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          > 
                <div class="card card-custom gutter-b">
                  <div class="card-header">
										<div class="card-title">
											<span class="card-icon">
												<i class="flaticon-users-1 text-danger"></i>
											</span>
											<h3 class="card-label">{pessoa.dp_nome_rsociao}</h3><br />
                      <small>{pessoa.cidade}</small>
										</div>
                    <div class="card-toolbar">
                      {pessoa.tipo_cadastro === "Cadastro" && <>          
                        <span className="label label-info label-inline mr-2">Cadastro</span>
                      </>}
                      {pessoa.tipo_cadastro === "Sócio" && <>          
                        <span className="label label-success label-inline mr-2">Sócio</span>
                      </>}
                      {pessoa.tipo_cadastro === "Desativado" && <>          
                        <span className="label label-danger label-inline mr-2">Desativado</span>
                      </>}
                      {pessoa.tipo_cadastro === "Inativo" && <>          
                        <span className="label label-secondary label-inline mr-2">Inativo</span>
                      </>}
										</div>                    
                  </div>
                  <div class="card-body">
                    <i class="fas fa-envelope icon-md text-info" /> <b className="ml-2"> {pessoa.email}</b><br />
                    <i class="fas fa-phone-alt icon-md text-info" />
                      <b>
                        {pessoa.telefone1 !== null && <label className="pt-2 pl-2 pr-2 pb-2 ml-2">{pessoa.telefone1}</label>}
                        {pessoa.telefone2 !== null && <label className="pt-2 pl-2 pr-2 pb-2 ml-2">{pessoa.telefone2}</label>}
                        {pessoa.celular1 !== null && <label className="pt-2 pl-2 pr-2 pb-2 ml-2">{pessoa.celular1}</label>}
                      </b>
                  </div>
                </div>

                <div class="card card-custom gutter-b">
                  <div class="card-header">
										<div class="card-title">
											<span class="card-icon">
												<i class="fas fa-bell text-success"></i>
											</span>
											<h3 class="card-label">Detalhes da Inscrição</h3>
										</div>                    
                  </div>
                  <div class="card-body">
                    <div className="row">
                      <div className="col-md-9">
                        <label><b>Evento</b></label>
                      </div>
                      <div className="col-md-3 text-center">
                        <label><b>Valor</b></label>
                      </div>
                    </div>
                    { itens.map((item) => { 
                      return (
                      <>
                      <hr />
                      <div className="row">
                        <div className="col-md-9">
                          <label>{item.titulo}</label>
                        </div>
                        <div className="col-md-3 text-center">
                          <label><b>{item.socio}</b></label>
                        </div>
                      </div>
                      </>
                      )}
                    )}
                    <hr />
                      <div className="row">
                      {pessoa.tipo_cadastro === "Cadastro" ? 
                        <div className="col-md-12 text-right">
                          <label>Valor total R$ <b>{formata(valorPedido_ns,2)}</b></label>
                        </div>
                        :
                        <>
                        <div className="col-md-12 text-right">
                          <label>Total sem desconto R$ <b>{formata(valorPedido_ns,2)}</b></label>
                        </div>
                        <div className="col-md-12 text-right text-danger">
                          <label>Desconto sócio R$ <b>{formata(desconto,2)}</b></label>
                        </div>
                        <div className="col-md-12 text-right">
                          <label>Valor total R$ <b>{formata(valorPedido_s,2)}</b></label>
                        </div>
                        </>
                      }
                      </div>
                    
                    <div className="row mt-5">
                      <div className="col-md-2">
                        <div class="btn-group">
                          <button className="btn btn-danger btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="flaticon-delete-1" />Apagar</button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" onClick={e => { apagarCotacao(cotacao.id, "Duplicada") }}>Duplicada</a>
                            <a class="dropdown-item" onClick={e => { apagarCotacao(cotacao.id, "Inválida") }}>Inválida</a>
                            <a class="dropdown-item" onClick={e => { apagarCotacao(cotacao.id, "Spam/Teste") }}>Spam/Teste  </a>
                          </div>
                        </div>
                      </div>
                      <div className="col text-right">
                        <button className="btn btn-success btn-sm ml-2" onClick={e=>{enviar()}}><i class="flaticon2-check-mark" />Confirmar Inscrição</button>
                      </div>
                    </div>
                  </div>
                </div>
                </LoadingOverlay>
              </div>
              }

            </div>
          </div>
        

        
                  
                </div>
              </div>  

      <Modal size={'sm'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header className="bg-warning">
      <Modal.Title>Encaminhar E-mail de Cotação</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>E-mail</label>
          <input type="text" value={emailEncaminhar} onChange={e => {setEmailEncaminhar(e.target.value)}} className="form-control" />
        </div>        
      </div>    
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { encaminhar(); }} className="btn btn-warning font-weight-bold"><i class="flaticon-reply" />Encaminhar</button>
      </Modal.Footer>
    </Modal>
      
        </>
    );

}

export default withRouter(Inscricoes);                  