import React, { useState, useEffect, useRef } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import Select from 'react-select';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import ReactToPrint from "react-to-print";

import "./index.scss";

import { Modal } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const EventoFinanceiro = () => {
  const { addToast } = useToasts();
  const [ financeiroDespesa, setFinanceiroDespesa ] = useState([]);
  const [ financeiroReceita, setFinanceiroReceita ] = useState([]);
  const [ evento, setEvento ] = useState([]);
  const [ totalDespesa, setTotalDespesa ] = useState();
  const [ totalReceita, setTotalReceita ] = useState();
  const [ total, setTotal ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ formFinanceiro, setFormFinanceiro ] = useState({});
  const [ categoriaSelect, setCategoriaSelect ] = useState([]);

  const printRef = useRef();

  function fecharModal(){
    setModal(false);
  }
  
   useEffect(() => {
    getCategoriaSelect();
    getListar();
  }, []);

  function getCategoriaSelect() {
    Api.get("fincategoria/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... Categoria'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setCategoriaSelect(nLis);
    })
  }

  function getListar() { 
    var data = {
      evento: sessionStorage.getItem('evento')
    }

    setCaProcessa(true); 
    Api.post("eventofinanceiro/listar", data).then(rps => {
      
      setFinanceiroDespesa(rps.data.despesa);
      setFinanceiroReceita(rps.data.receita);
      setEvento(rps.data.evento);
      setTotalDespesa(rps.data.total_despesa);
      setTotalReceita(rps.data.total_receita);
      setTotal(rps.data.total);

      setCaProcessa(false);
    })
  }
  
  function adicionar(){
    setFormFinanceiro({});
    setModal(true);
  }

  function editar(id){
    console.log(id);  
    var ca = JSON.parse(JSON.stringify(id));
    ca.data = new Date(ca.data + ' 12:00:00');
    console.log(ca);
    setFormFinanceiro(ca);
    setModal(true);            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('eventofinanceiro/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);
    formFinanceiro.id_evento = sessionStorage.getItem('evento');
    Api.post('eventofinanceiro/adicionar', formFinanceiro).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormFinanceiro({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Evento</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              <h6 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Despesas/Receita</h6>
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <ReactToPrint
                trigger={() => <button className="btn btn-md btn-secondary mt-6 mr-3"><i className="fas fa-print" />Imprimir</button>}
                content={() => printRef.current }
              />
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-2">
                  <img src={"/img/eventos/"+evento.programacao} width="100px" />
                </div>

                <div className="form-group col-md-8 ">
                  <h3><b>{evento.titulo}</b></h3>
                  <h4>{evento.cidade} - {evento.estado}</h4>
                  <h5>{evento.dt_inicial} - {evento.dt_final}</h5>
                </div>
                               
                </div> 
              </div>
            </div>

              <br />

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <h5 className="mb-5">Despesas</h5>
                
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Categoria</th>
                      <th>Descrição das despesas</th>
                      <th class="text-center">Data</th>
                      <th width="10%" className="text-center">Valor</th>
                      <th width="15%" className="text-center">Ação</th>
                    </tr>
                  </thead>
                  { financeiroDespesa.map( item => {
                    return (
                      <>
                        <tbody>
                          <tr>
                            <td>{item.nome}</td>
                            <td>{item.descricao}</td>
                            <td className="text-center">{item.dataFormatada}</td>
                            <td className="text-right">{item.valor}</td>
                            <td className="text-center">
                              <button className="btn btn-warning btn-sm" onClick={e => { editar(item) }}><i className="fas fa-pencil-alt" />Alterar</button>
                              <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(item) }}><i className="far fa-trash-alt" />Excluir</button>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )
                  })}
                  <tr>
                    <td colspan="3" class="text-right"><b>Total</b></td>
                    <td class="text-right"><b>{totalDespesa}</b></td>
                    <td></td>
                  </tr>
                </table> 
              </div>
            </div>

            <br /><br />

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <h5 className="mb-5">Receitas</h5>
                
                <table className="table table-striped">
                  <tr>
                    <th>Categoria</th>
                    <th>Descrição das receitas</th>
                    <th class="text-center">Data</th>
                    <th width="10%" className="text-center">Valor</th>
                    <th width="15%" className="text-center">Ação</th>
                  </tr>
                  { financeiroReceita.map( item => {
                    return (
                      <>
                        <tr>
                          <td>{item.nome}</td>
                          <td>{item.descricao}</td>
                          <td className="text-center">{item.dataFormatada}</td>
                          <td className="text-right">{item.valor}</td>
                          <td className="text-center">
                            <button className="btn btn-warning btn-sm" onClick={e => { editar(item) }}><i className="fas fa-pencil-alt" /> Alterar</button>
                            <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(item) }}><i className="far fa-trash-alt" />Excluir</button>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                  <tr>
                    <td colspan="3" class="text-right"><b>Total</b></td>
                    <td class="text-right"><b>{totalReceita}</b></td>
                    <td></td>
                  </tr>
                </table> 
              </div>
            </div>    
            <br />
            <h2 className="text-right"><b>Total Geral {total}</b></h2>
              </LoadingOverlay>                    
      
                  <br /><br /><br />
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Evento - Despesas/Receitas</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Categoria'</label>
          <Select options={categoriaSelect}
                  placeholder="Selecione... Categoria" 
                  defaultValue={categoriaSelect.find(
                    x => x.value === formFinanceiro.id_categoria
                  )}
                  onChange={(e) => { setFormFinanceiro({...formFinanceiro, id_categoria: e.value}) }}/>
        </div>

        <div className="form-group col-md-9">
          <label>Descrição'</label>
          <input type="text" value={formFinanceiro?.descricao} onChange={e => {setFormFinanceiro({...formFinanceiro, descricao: e.target.value})}} className="form-control" />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Tipo</label>
          <select className="form-control" value={formFinanceiro?.custo} onChange={e => {setFormFinanceiro({...formFinanceiro, custo: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="D" >Despesa</option>    
            <option value="R" >Receita</option>    
          </select>
        </div>

        <div className="col-md-3">
          <label>Data</label><br />
          <DatePicker 
            name="dt_inicio"
            onChange={(e) => { setFormFinanceiro({...formFinanceiro, data: e}) }}
            isClearable
            selectsStart
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa" 
            selected ={formFinanceiro.data}
            dateFormat="dd/MM/y"
            customInput={
              <InputMask mask="99/99/9999" />}/>
        </div>

        <div className="form-group col-md-3">
          <label>Valor</label>
          <input type="text" value={formFinanceiro?.valor} onChange={e => {setFormFinanceiro({...formFinanceiro, valor: e.target.value})}} className="form-control" />
        </div>
      </div>
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        <div className="relatorio">
          <div ref={printRef}>
            <table>
              <tr>
                <td width="40%">
                  <img src={"/img/eventos/"+evento.programacao} width="100px" />
                </td>
                <td width="60%">
                  <h5><b>{evento.descricao}</b><br />
                      {evento.titulo} <br />
                      {evento.mes} - {evento.ano}</h5>
                </td>
              </tr>
            </table>

            <h5 className="mb-5">Despesas</h5>
            
            <table className="table table-striped">
              <thead>
                <tr>
                  <th width="20%">Categoria</th>
                  <th width="50%">Descrição das despesas</th>
                  <th width="15%" class="texto-center">Data</th>
                  <th width="15%" className="texto-center">Valor</th>
                </tr>
              </thead>
              { financeiroDespesa.map( item => {
                    return (
                      <>
                        <tbody>
                          <tr>
                            <td className="texto-center">{item.id}</td>
                            <td>{item.descricao}</td>
                            <td className="texto-center">{item.dataFormatada}</td>
                            <td className="texto-right">{item.valor}</td>
                          </tr>
                        </tbody>
                      </>
                    )
                  })}
                  <tr>
                    <td colspan="3" class="texto-right"><b>Total</b></td>
                    <td class="texto-right"><b>{totalDespesa}</b></td>
                    <td></td>
                  </tr>
                </table>
                
                <h5 className="mb-5">Receitas</h5>
                
                <table className="table table-striped">
                  <tr>
                    <th width="20%">Categoria</th>
                    <th width="50%">Descrição das receitas</th>
                    <th width="15%" class="texto-center">Data</th>
                    <th width="15%" className="texto-center">Valor</th>
                  </tr>
                  { financeiroReceita.map( item => {
                    return (
                      <>
                        <tr>
                          <td>{item.nome}</td>
                          <td>{item.descricao}</td>
                          <td className="texto-center">{item.dataFormatada}</td>
                          <td className="texto-right">{item.valor}</td>
                       </tr>
                      </>
                    )
                  })}
                  <tr>
                    <td colspan="3" class="texto-right"><b>Total</b></td>
                    <td class="texto-right"><b>{totalReceita}</b></td>
                    <td></td>
                  </tr>
                </table>

                <p className="texto-right"><b>Total Geral {total}</b></p>
          </div>
        </div>
        </>
    );
}

export default withRouter(EventoFinanceiro);                  