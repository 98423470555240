import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const RevistaStab = () => {
  const { addToast } = useToasts();
  const [ formSiteRevista, setFormSiteRevista ] = useState({});
  const [ caProcessa, setCaProcessa ] = useState(false);
  
   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("siterevista/listar_revista").then(rps => {
      
      setFormSiteRevista(rps.data.obj);
    
      setCaProcessa(false);
    })
  }
  
  function salvar(){
    setCaProcessa(true);

    Api.post('siterevista/atualizar_revista', formSiteRevista).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mt-7 mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Conteúdo do Site</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
               {/*begin::Title*/}
               <h5 className="text-dark font-weight-bold mr-5">Revista</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Revista STAB</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
            <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >

              <div className="ml-10 mr-10 mt-10">    
                
                <div className="row">
                  <div className="form-group col-md-2">
                    <label>Data da última atualização</label>
                    <input className="form-control" 
                      value={formSiteRevista.revista_at} 
                      disabled />
                  </div>

                  <div className="form-group col-md-8">
                  </div>
                  
                  <div className="form-group col-md-2">
                    <label>Status</label>
                    <select className="form-control" value={formSiteRevista.revista_ativo} 
                      onChange={(e) => { setFormSiteRevista({...formSiteRevista, revista_ativo: e.target.value}) }}>
                        <option value="" selected>Selecione</option>    
                        <option value="S">Sim</option>    
                        <option value="N">Não</option>    
                      </select>
                  </div>
                </div> 

                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>Título</label>
                    <input className="form-control"
                      placeholder="Título" 
                      value={formSiteRevista.revista_titulo} 
                      onChange={(e) => { setFormSiteRevista({...formSiteRevista, revista_titulo: e.target.value}) }}/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>Revista</label>
                    <CKEditor
                      editor={ ClassicEditor }
                      data={formSiteRevista.revista}
                      onChange={( event, editor ) => { setFormSiteRevista({...formSiteRevista, revista: editor.getData()}) }}            
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 text-right mb-5">
                    <Link to='/painel/' className="btn btn-secondary font-weight-bold mr-2"><i className="fas fa-door-open" />Fechar</Link>
                    <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
                  </div>
                </div>
              </div>                              

              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
                 
        </>
    );
}

export default withRouter(RevistaStab);                  