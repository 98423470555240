import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

const Regiao = () => {
  const { addToast } = useToasts();
  const [ regiaos, setRegiaos ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaNome, setBuscaNome ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formRegiao, setFormRegiao ] = useState({});

  function fecharModal(){
    setModal(false);
  }

  const data = regiaos;
  const columns = [
    {
      name: <th>ID</th>,
      selector: 'id',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Nome</th>,
      selector: 'nome',
      width: '48%',
      sortable: true,
    },
    {
      name: <th>Data Criação</th>,
      width: '13%',
      sortable: true,
      selector: 'data_criacao',
      center: 'true'
    }, 
    {
      name: <th>Data Alteração</th>,
      width: '13%',
      sortable: true,
      selector: 'data_atualizacao',
      center: 'true'
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>

    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("regiao/listar").then(rps => {
      
      setRegiaos(rps.data.obj);
      setCaProcessa(false);
    })
  }
  
  function adicionar(){
    setFormRegiao({});
    setModal(true);
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setFormRegiao(ca);
    setModal(true);            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('regiao/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);
    Api.post('regiao/adicionar', formRegiao).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormRegiao({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
      };
    
      Api.post('regiao/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setRegiaos(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Região</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col-md-10">
                  <label>Nome</label>
                  <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Regiões"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Região</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Nome</label>
          <input type="text" value={formRegiao?.nome} onChange={e => {setFormRegiao({...formRegiao, nome: e.target.value})}} className="form-control" />
        </div>      
      </div>    
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Regiao);                  