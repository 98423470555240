import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import Axios from 'axios';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

const Livros = () => {
  const { addToast } = useToasts();
  const refCapa = React.useRef(null);
  const [ pubLivros, setPubLivros ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaTitulo, setBuscaTitulo ] = useState();
  const [ buscaAutores, setBuscaAutores ] = useState();
  const [ buscaEditora, setBuscaEditora ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formLivros, setFormLivros ] = useState({});
  const [ acao, setAcao ] = useState();

  function fecharModal(){
    setModal(false);
  }

  const data = pubLivros;
  const columns = [
    {
      name: <th>Capa</th>,
      width: '10%',
      center: true,
      cell: row => <img src={"https://www.stabsul.info/motorStab/assets/img/livros/"+row.capa} width="100px" />,
    },
    {
      name: <th>Título</th>,
      selector: 'titulo',
      sortable: true,
      wrap: true,
      width: '25%',
    },
    {
      name: <th>Autores</th>,
      selector: 'autores',
      width: '25%',
      wrap: true,
      sortable: true,
    },
    {
      name: <th>Editora</th>,
      selector: 'editora',
      width: '14%',
      wrap: true,
      sortable: true,
    },
    {
      name: <th>Valor Sócio</th>,
      selector: 'valor_s',
      width: '10%',
      right: true,
      sortable: true,
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>

    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    var data = {
      tipo: 'L',
    };
  
    Api.post('sitepublicacoes/listar', data).then((rps) => {
      
      setPubLivros(rps.data.obj);
      setCaProcessa(false);
    })
  }
  
  function adicionar(){
    setFormLivros({});
    setModal(true);
    setAcao('Adicionar');
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setAcao('Alterar');
    setFormLivros(ca);
    setModal(true);            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('sitepublicacoes/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    setCaProcessa(true);
    formLivros.tipo = 'L';
    Api.post('sitepublicacoes/adicionar', formLivros).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListar();
        setAcao('Alterar');
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        titulo: buscaTitulo,
        autores: buscaAutores,
        editora: buscaEditora,
        tipo: 'L',
      };
    
      Api.post('sitepublicacoes/listar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPubLivros(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
  
  async function handleCapaChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formLivros.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('sitepublicacoes/capa', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormLivros({...formLivros, capa: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mt-7 mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Conteúdo do Site</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Publicações</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
               {/*begin::Title*/}
               <h5 className="text-dark font-weight-bold mr-5">Livros</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-4">
                  <label>Título</label>
                  <input type="text" value={buscaTitulo} onChange={e => {setBuscaTitulo(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-3">
                  <label>Autores</label>
                  <input type="text" value={buscaAutores} onChange={e => {setBuscaAutores(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-3">
                  <label>Editora</label>
                  <input type="text" value={buscaEditora} onChange={e => {setBuscaEditora(e.target.value)}} className="form-control" />
                </div>

                <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Livros"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Livros</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-10">
          <label>Título <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.titulo} onChange={e => {setFormLivros({...formLivros, titulo: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Disponível <span class="text-danger">*</span></label>
          <select className="form-control" value={formLivros.status} 
            onChange={(e) => { setFormLivros({...formLivros, status: e.target.value}) }}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>    
              <option value="E">Esgotado</option>    
          </select>
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Autores <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.autores} onChange={e => {setFormLivros({...formLivros, autores: e.target.value})}} className="form-control" />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Tradução <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.traducao} onChange={e => {setFormLivros({...formLivros, traducao: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-6">
          <label>Editora <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.editora} onChange={e => {setFormLivros({...formLivros, editora: e.target.value})}} className="form-control" />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-2">
          <label>Ano </label>
          <input type="text" value={formLivros?.ano} onChange={e => {setFormLivros({...formLivros, ano: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Página </label>
          <input type="text" value={formLivros?.pagina} onChange={e => {setFormLivros({...formLivros, pagina: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Qtde. Estoque <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.qtde_estoque} onChange={e => {setFormLivros({...formLivros, qtde_estoque: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Qtde. Mínima <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.qtde_minima} onChange={e => {setFormLivros({...formLivros, qtde_minima: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Valor Sócio <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.valor_s} onChange={e => {setFormLivros({...formLivros, valor_s: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-2">
          <label>Valor Não Sócio <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.valor_ns} onChange={e => {setFormLivros({...formLivros, valor_ns: e.target.value})}} className="form-control" />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Peso <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.peso} onChange={e => {setFormLivros({...formLivros, peso: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Comprimento <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.comprimento} onChange={e => {setFormLivros({...formLivros, comprimento: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Altura <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.altura} onChange={e => {setFormLivros({...formLivros, altura: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Largura <span class="text-danger">*</span></label>
          <input type="text" value={formLivros?.largura} onChange={e => {setFormLivros({...formLivros, largura: e.target.value})}} className="form-control" />
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Conteúdo</label>
          <CKEditor
            editor={ ClassicEditor }
            data={formLivros.indice}
            onChange={( event, editor ) => { setFormLivros({...formLivros, indice: editor.getData()}) }}            
          />
        </div>
      </div>

      { acao == 'Alterar' && <>
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Imagem (Capa)</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
              onClick={e => {refCapa.current.click();} } >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formLivros?.capa ? "https://www.stabsul.info/motorStab/assets/img/livros/"+formLivros?.capa : "/img/livros/no-image.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input type="file" ref={refCapa} name="imagemLogo" onChange={(e) => handleCapaChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
            </div>
          </div>
        </div>
      </div>
      </> }   
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Livros);                  