import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import Axios from 'axios';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Palestra = () => {
  const { addToast } = useToasts();
  const refPalestra = React.useRef(null);
  const [ palestras, setPalestras ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaPalestra, setBuscaPalestra ] = useState();
  const [ buscaPalestrante, setBuscaPalestrante ] = useState();
  const [ buscaEmpresa, setBuscaEmpresa ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formPalestra, setFormPalestra ] = useState({});
  const [ acao, setAcao ] = useState();

  function fecharModal(){
    setModal(false);
  }

  const data = palestras;
  const columns = [
    {
      name: <th>Palestra</th>,
      selector: 'palestra',
      width: '30%',
      sortable: true,
    },
    {
      name: <th>Palestrante</th>,
      selector: 'palestrante',
      width: '22%',
      sortable: true,
    },
    {
      name: <th>Empresa</th>,
      width: '14%',
      sortable: true,
      selector: 'empresa',
    },
    {
      name: <th>Tipo</th>,
      width: '8%',
      sortable: true,
      selector: 'tipo',
      center: true,
      cell: row => { 
        return <>
            {row.tipo === "A" && <>          
              <i class="far fa-file-pdf text-danger mr-5" />
            </>}
            {row.tipo === "V" && <>          
              <i class="fa fa-video text-dark mr-5" />
            </>}
          </>
        }
    },
    {
      name: <th>Disponível</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: true,
      cell: row => { 
        return <>
            {row.status === "S" && <>          
              <span className="label label-success label-inline mr-2">Sim</span>
            </>}
            {row.status === "N" && <>          
              <span className="label label-secondary label-inline mr-2">Não</span>
            </>}
          </>
        }
    }, 
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button className="btn btn-warning btn-sm" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>
    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 

    var data = {
      evento: sessionStorage.getItem('evento'),
    };

    Api.post("eventopalestras/filtrar", data).then(rps => {
      
      setPalestras(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function adicionar(){
    setFormPalestra({});
    
    setModal(true);
    setAcao('Adicionar');
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setFormPalestra(ca);
    setModal(true); 
    setAcao('Alterar');            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('eventopalestras/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function salvar(){
    formPalestra.idDownload = sessionStorage.getItem('evento');
    setCaProcessa(true);

    Api.post('eventopalestras/adicionar', formPalestra).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        //setModal(false);
        setFormPalestra({...formPalestra, id: rps.data.evento});
        getListar();
        setAcao('Alterar');
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  async function handleArquivoChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formPalestra.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('eventopalestras/capa', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setFormPalestra({...formPalestra, arquivo: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        palestrante: buscaPalestrante,
        palestra: buscaPalestra,
        empresa: buscaEmpresa,
        status: buscaStatus,
        evento: sessionStorage.getItem('evento'),
      };
    
      Api.post('eventopalestras/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPalestras(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Evento</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Palestra</h5>
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-3">
                  <label>Palestra</label>
                  <input type="text" value={buscaPalestra} onChange={e => {setBuscaPalestra(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-3">
                  <label>Palestrante</label>
                  <input type="text" value={buscaPalestrante} onChange={e => {setBuscaPalestrante(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-3">
                  <label>Empresa</label>
                  <input type="text" value={buscaEmpresa} onChange={e => {setBuscaEmpresa(e.target.value)}} className="form-control" />
                </div>
                
                <div className="form-group col-md-1">
                  <label>Disponível</label>
                    <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Sim</option>    
                      <option value="N">Não</option>    
                    </select>
                </div>

                <div className="col form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Palestras"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Palestra</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-8">
          <label>Palestra</label>
          <input type="text" value={formPalestra?.palestra} onChange={e => {setFormPalestra({...formPalestra, palestra: e.target.value})}} className="form-control" />
        </div>
        <div className="form-group col-md-2">
          <label>Disponível</label>
            <select className="form-control" value={formPalestra?.status} onChange={e => {setFormPalestra({...formPalestra, status: e.target.value})}}>
              <option value="" selected>Selecione</option>    
              <option value="S">Sim</option>    
              <option value="N">Não</option>    
            </select>
        </div>
        <div className="form-group col-md-2">
          <label>Tipo</label>
            <select className="form-control" value={formPalestra?.tipo} onChange={e => {setFormPalestra({...formPalestra, tipo: e.target.value})}}>
              <option value="" selected>Selecione</option>    
              <option value="A">Arquivo (PDF)</option>    
              <option value="V">Vídeo</option>    
            </select>
        </div>
      </div>

      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Palestrante</label>
          <input type="text" value={formPalestra?.palestrante} onChange={e => {setFormPalestra({...formPalestra, palestrante: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-6">
          <label>Empresa</label>
          <input type="text" value={formPalestra?.empresa} onChange={e => {setFormPalestra({...formPalestra, empresa: e.target.value})}} className="form-control" />
        </div>
      </div>

      { formPalestra?.tipo == 'A' &&
      <div className="row ml-5 mr-5">
        <div class="form-group col-md-12">
          <label>Tópicos</label>
          <CKEditor
            editor={ ClassicEditor }
            data={formPalestra?.topicos}
            onChange={( event, editor ) => { setFormPalestra({...formPalestra, topicos: editor.getData()}) }}            
        />
        </div>
      </div>
      }

      { formPalestra?.tipo == 'V' &&
      <div className="row ml-5 mr-5">  
        <div class="form-group col-md-6">
          <label>Identificador do vídeo</label>
          <input type="text" value={formPalestra?.topicos} onChange={e => {setFormPalestra({...formPalestra, topicos: e.target.value})}} className="form-control" />
        </div>
        <div class="form-group col-md-6">
          <label className="text-danger">Inserir apenas o identificador do Youtube</label><br />
          <label>Ex: https://www.youtube.com/watch?v=<span className="text-danger">C2YdKzcE5p8</span></label>     
        </div>
      </div>
      }

      { acao == 'Alterar' && formPalestra?.tipo == 'A' && <>
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-6">
          <label>Arquivo (PDF) {formPalestra?.arquivo ? <a className="label label-success label-inline mr-2" href={"https://www.stabsul.info/motorStab/assets/publicacoes/"+formPalestra.arquivo} target="_blank">Clique aqui para abrir o arquivo</a> : ""}</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
              onClick={e => {refPalestra.current.click();} } >
              <div className="dropzone-preview dropzone__preview">
                <img className="dropzone-preview__image" src={formPalestra?.arquivo ? "/img/revista/artigo/arquivo_pdf.jpg" : "/img/revista/capa/nao_disponivel.jpg"} alt="" />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar arquivo</span>
              </div>
              <input type="file" ref={refPalestra} name="imagemLogo" onChange={(e) => handleArquivoChange(e)} accept=".pdf" className="classeInputImage" />
            </div>
          </div>
        </div>
      </div>
      </>}  
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Palestra);                  