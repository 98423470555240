import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import Axios from 'axios';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

//import CKEditor from "ckeditor4-react";
import CKEditor from 'ckeditor4-react-advanced';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
import { plugins } from 'chart.js';
registerLocale("pt-BR", pt);

const GrupoLatino = () => {
  const { addToast } = useToasts();
  const refPalestra = React.useRef(null);
  const [ formSiteMenu, setFormSiteMenu ] = useState({});
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ formPalestra, setFormPalestra ] = useState({});
  const [ palestra, setPalestra ] = useState({});
  
   useEffect(() => {
    getListar();
    getListarPalestra();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("sitemenu/listar_grupo").then(rps => {
      
      setFormSiteMenu(rps.data.obj);
      console.log(rps.data.obj);

      setCaProcessa(false);
    })
  }

  function getListarPalestra() { 
    setCaProcessa(true); 
    Api.get("sitemenu/listar_palestra").then(rps => {
      
      setPalestra(rps.data.obj);
      console.log(rps.data.obj);

      setCaProcessa(false);
    })
  }

  function fecharModal(){
    setModal(false);
  }
  
  function adicionar(){
    setFormPalestra({});
    setModal(true);
  }

  function salvar(){
    setCaProcessa(true);

    Api.post('sitemenu/atualizar_grupo', formSiteMenu).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function salvarPalestra(){
    setCaProcessa(true);

    Api.post('sitemenu/atualizar_grupo_palestra', formPalestra).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      getListarPalestra();
      setCaProcessa(false);
      setModal(false);
    });
  }

  async function handleArquivoChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id', formPalestra.id);
      
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('sitemenu/arquivo_pdf', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      setFormPalestra({...formPalestra, arquivo: response.data.url_imagem});
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  const data = palestra;
  const columns = [
    {
      name: <th>ID</th>,
      selector: 'id',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Palestra</th>,
      selector: 'palestra',
      width: '48%',
      sortable: true,
      wrap: true,
    },
    {
      name: <th>Status</th>,
      width: '10%',
      sortable: true,
      selector: 'ativo',
      center: 'true',
      cell: row => { if (row.ativo == "S") {return <span class="label label-success label-inline mr-2">Ativo</span>}
        else {return <span class="label label-secondary label-inline mr-2">Desativado</span>}
      }
    },
    {
      name: <th>Arquivo PDF</th>,
      width: '15%',
      sortable: true,
      selector: 'ativo',
      center: 'true',
      cell: row =>  { return (<>
        {row.arquivo != null ? <a className="btn btn-success btn-sm" href={"https://www.stabsul.info/motorStab/assets/palestras/"+row.arquivo} ><i className="fas fa-file-pdf" />Abrir palestra</a> : <span class="label label-dark label-inline mr-2"><i className="fas fa-ban mr-1" /> Não disponível</span>}
      </>)
      }
    },

    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button className="btn btn-warning btn-sm ml-2" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
        <button className="btn btn-danger btn-sm ml-2" onClick={e => { excluir(row) }}><i className="far fa-trash-alt" />Excluir</button>

    </>)
      },
    },
  ];

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setFormPalestra(ca);
    setModal(true);            
  }

  function excluir(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('sitemenu/excluir_grupo_palestra', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListarPalestra();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }


    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mt-7 mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mr-5">Conteúdo do Site</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mr-5 bg-gray-200" />
              {/*end::Separator*/}
               {/*begin::Title*/}
               <h5 className="text-dark font-weight-bold mr-5">Grupo Latino Americano</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
            <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >

              <div className="ml-10 mr-10 mt-10">    
                
                <div className="row">
                  <div className="form-group col-md-2">
                    <label>Data da última atualização</label>
                    <input className="form-control" 
                      value={formSiteMenu.latino_at} 
                      disabled />
                  </div>

                  <div className="form-group col-md-8">
                  </div>
                  
                  <div className="form-group col-md-2 text-right">
                    <button className="btn btn-md btn-info mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Cadastrar Palestra</button>
                  </div>
                </div> 

                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>Título</label>
                    <input className="form-control"
                      placeholder="Título" 
                      value={formSiteMenu.latino_titulo} 
                      onChange={(e) => { setFormSiteMenu({...formSiteMenu, latino_titulo: e.target.value}) }}/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>Texto</label>
                    <CKEditor
                      data={formSiteMenu.latino_texto}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setFormSiteMenu({...formSiteMenu, latino_texto: data}) }}
                      //onChange={( event, editor ) => { setFormSiteMenu({...formSiteMenu, latino_texto: editor.getData()}) }}            
                      config={ {
                        toolbar: [
                          { name: "styles", items: ["Format", "-", "Font",] },
                          {
                            name: "clipboard",
                            items: ["Undo", "Redo"]
                          },
                          {
                            name: "basicstyles",
                            items: [
                              "Bold",
                              "Italic",
                              "Underline",
                              "Strike",
                              "-",
                              "Subscript",
                              "Superscript"
                            ]
                          },
                          { name: "links", items: ["Link", "Unlink"] },
                          {
                            name: "paragraph",
                            items: [
                              "NumberedList",
                              "BulletedList",
                              "-",
                              "Outdent",
                              "Indent",
                              "Blockquote"
                            ]
                          },
                          {
                            name: "align",
                            items: [
                              "AlignLeft",
                              "JustifyLeft",
                              "JustifyCenter",
                              "JustifyRight",
                              "JustifyBlock"
                            ]
                          },
                          { name: "colors", items: ["TextColor", "BGColor"] },
                          { name: "insert", items: ["Image", "Table", "HorizontalRule"] },
                          { name: "document", items: ["Source"] },
                        ],
                      //width: '600px',
                      //height: '350px',
                        }
                      }
                    />
                  </div>
                </div>

                <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Palestras"
                  columns={columns}
                  data={data}
                  striped="true"                   
                />
              </LoadingOverlay>

                <div className="row mt-10">
                  <div className="col-md-12 text-right mb-5">
                    <Link to='/painel/' className="btn btn-secondary font-weight-bold mr-2"><i className="fas fa-door-open" />Fechar</Link>
                    <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
                  </div>
                </div>
              </div>                              

              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
                    <Modal.Header>
                    <Modal.Title>Cadastro de Paletras</Modal.Title>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
                          <i aria-hidden="true" className="ki ki-close" />
                        </button>
                    </Modal.Header>
                    <div className="row mt-5 ml-5 mr-5">
                      <div className="form-group col-md-10">
                        <label>Título</label>
                        <input type="text" value={formPalestra?.palestra} onChange={e => {setFormPalestra({...formPalestra, palestra: e.target.value})}} className="form-control" />
                      </div>
                      
                      <div className="form-group col-md-2">
                        <label>Status</label>
                        <select className="form-control" value={formPalestra?.ativo} onChange={e => {setFormPalestra({...formPalestra, ativo: e.target.value})}}>
                          <option value="" selected>Selecione</option>    
                          <option value="S" >Ativo</option>    
                          <option value="N" >Desativado</option>    
                        </select>
                      </div>
                    
                    </div>    

                    <div className="row ml-5 mr-5">
                      <div className="form-group col-md-6">
                        <label>Arquivo (PDF) {formPalestra?.arquivo ? <a className="label label-success label-inline mr-2" href={"https://www.stabsul.info/motorStab/assets/palestras/"+formPalestra.arquivo} target="_blank">Clique aqui para abrir o arquivo</a> : ""}</label>
                        <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                          <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
                            onClick={e => {refPalestra.current.click();} } >
                            <div className="dropzone-preview dropzone__preview">
                              <img className="dropzone-preview__image" src={formPalestra?.arquivo ? "/img/revista/artigo/arquivo_pdf.jpg" : "/img/revista/capa/nao_disponivel.jpg"} alt="" />
                            </div>
                            <div className="dropzone-placeholder dropzone__placeholder">
                              <span>Trocar arquivo</span>
                            </div>
                            <input type="file" ref={refPalestra} name="imagemLogo" onChange={(e) => handleArquivoChange(e)} accept=".pdf" className="classeInputImage" />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <Modal.Footer>
                      <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                      <button type="button" onClick={e => { salvarPalestra(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar Palestra</button>
                    </Modal.Footer>
                  </Modal>       
        </>
    );
}

export default withRouter(GrupoLatino);                  