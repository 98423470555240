import React, { useState, useEffect, useRef } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import ReactToPrint from "react-to-print";

import "./index.scss";

const Retiquetas = () => {
  const { addToast } = useToasts();
  const [ relatorio, setRelatorio ] = useState([]);
  const [ qtdRegistro, setQtdeRegistro ] = useState(0);
  const [ anoSelect, setAnoSelect ] = useState([]);
  const [ tipoPessoaSelect, setTipoPessoaSelect ] = useState([]);
  const [ tipoSocioSelect, setTipoSocioSelect ] = useState([]);
  const [ areaAtuacaoSelect, setAreaAtuacaoSelect ] = useState([]);
  const [ regionalSelect, setRegionalSelect ] = useState([]);
  const [ buscaRevista, setBuscaRevista ] = useState();
  const [ buscaTipoCadastro, setBuscaTipoCadastro ] = useState();
  const [ buscaAnuidade, setBuscaAnuidade ] = useState();
  const [ buscaTipoPessoa, setBuscaTipoPessoa ] = useState();
  const [ buscaTipoSocio, setBuscaTipoSocio ] = useState();
  const [ buscaAreaAtuacao, setBuscaAreaAtuacao ] = useState();
  const [ buscaRegional, setBuscaRegional ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const printRef = useRef();

  useEffect(() => {
    getAnoSelect();
    getTipoPessoaSelect();
    getTipoSocioSelect();
    getAreaAtuacaoSelect();
    getRegionalSelect();
  }, []);

  function abreLink() {

    sessionStorage.setItem('sessionBuscaTipoCadastro', buscaTipoCadastro);
    sessionStorage.setItem('sessionBuscaRevista', buscaRevista);
    sessionStorage.setItem('sessionBuscaAnuidade', buscaAnuidade);
    sessionStorage.setItem('sessionBuscaTipoPessoa', buscaTipoPessoa);
    sessionStorage.setItem('sessionBuscaTipoSocio', buscaTipoSocio);
    sessionStorage.setItem('sessionBuscaAreaAtuacao', buscaAreaAtuacao);
    sessionStorage.setItem('sessionBuscaRegional', buscaRegional);

    window.open("/sistema/relatorios/etiquetas/imprimir", "_blank", "toolbar=no,location=no,directories=no,status=yes,menubar=yes,scrollbars=yes,resizable=no,copyhistory=no,width=400,height=104");
  }
  
  function getAnoSelect() {
    Api.get("ano/listar").then(rps => {
      var reg = rps.data.obj;
      var nReg = [];
      nReg[nReg.length] = {value: '', label: 'Selecione...'};
      reg.forEach(e => {
        nReg[nReg.length] = {value: e.id, label: e.id};
      })
      setAnoSelect(nReg);
    })
  }

  function getTipoPessoaSelect() {
    Api.get("tipopessoa/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... Tipo pessoa'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setTipoPessoaSelect(nLis);
    })
  }

  function getTipoSocioSelect() {
    Api.get("tiposocio/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... Tipo sócio'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setTipoSocioSelect(nLis);
    })
  }

  function getAreaAtuacaoSelect() {
    Api.get("areaatuacao/listar_a").then(rps => {
      var lis = rps.data.obj;
      var nLis = [];
      nLis[nLis.length] = {value: '', label: 'Selecione... Área atuação'};
      lis.forEach(e => {
        nLis[nLis.length] = {value: e.id, label: e.nome};
      })
      setAreaAtuacaoSelect(nLis);
    })
  }

  function getRegionalSelect() {
    Api.get("regional/listar").then(rps => {
      var res = rps.data.obj;
      var nRes = [];
      nRes[nRes.length] = {value: '', label: 'Selecione... Regional'};
      res.forEach(e => {
        nRes[nRes.length] = {value: e.regional, label: e.regional};
      })
      setRegionalSelect(nRes);
    })
  }

  function buscar() {
    setCaProcessa(true); 
    var data = {
      tipo_cadastro: buscaTipoCadastro,
      revista: buscaRevista,
      anuidade: buscaAnuidade,
      tipo_pessoa: buscaTipoPessoa,
      tipo_socio: buscaTipoSocio,
      area_atuacao: buscaAreaAtuacao,
      regional: buscaRegional,
    };
    
    Api.post("relatorios/relatorioEtiquetas", data).then(rps => {
      
      if(rps.data.status==false){
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        setRelatorio(rps.data.obj);
        setQtdeRegistro(rps.data.qtde);
      }
    setCaProcessa(false); 
    })
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatórios</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Etiquetas</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              
              <button type="button" onClick={e => { abreLink(); }} className="btn btn-md btn-secondary mt-6 mr-5"><i className="fas fa-print" />Imprimir Etiqueta</button>
              <ReactToPrint
                trigger={() => <button className="btn btn-md btn-secondary mt-6"><i className="fas fa-print" />Imprimir Relatório</button>}
                content={() => printRef.current}
              />
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">          
            <div className="card card-custom">
              <div className="tab-content">
                <div className="tab-pane fade active show" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
                  
                  {/*begin::Accordion*/}
    <div className="accordion accordion-solid accordion-toggle-plus" id="accordionExample6">
      <div className="card">
        <div className="card-header" id="headingOne6">
          <div className="card-title" data-toggle="collapse" data-target="#collapseOne6">
            <i className="flaticon-price-tag" />Busca por Cadastros</div>
        </div>
        <div id="collapseOne6" className="collapse show" data-parent="#accordionExample6">
          <div className="card-body">
            
            
            <div className="row">
              <div className="col-md-3 form-group">
                <select className="form-control" value={buscaTipoCadastro} onChange={e => {setBuscaTipoCadastro(e.target.value)}}>
                  <option value="" selected>Selecione... Tipo de cadastro</option>    
                  <option value="Cadastro" >Cadastro</option>    
                  <option value="Desativado" >Desativado</option>    
                  <option value="Inativo" >Inativo</option>    
                  <option value="Sócio" >Sócio</option>    
                </select>
              </div>

              <div className="col-md-3 form-group">
                <select className="form-control" value={buscaRevista} 
                  onChange={e => {setBuscaRevista(e.target.value)}}>
                  <option value="" selected>Selecione... Revista</option>    
                  <option value="R1">Revista 1</option>    
                  <option value="R2">Revista 2</option>        
                  <option value="R3">Revista 3</option>
                  <option value="R4">Revista 4</option>        
                  <option value="R5">Revista 5</option>        
                </select>
              </div>

              <div className="col-md-3 form-group">
                <Select 
                  options={anoSelect}
                  placeholder="Selecione... Anuidade" 
                  defaultValue={anoSelect.find(
                    x => x.value === buscaAnuidade
                  )}
                  onChange={(e) => { setBuscaAnuidade(e.value) }}/>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 form-group">
                <Select 
                  options={tipoPessoaSelect}
                  placeholder="Selecione... Tipo de pessoa" 
                  defaultValue={tipoPessoaSelect.find(
                    x => x.value === buscaTipoPessoa
                  )}
                  onChange={(e) => { setBuscaTipoPessoa(e.value) }}
                />
              </div>
              <div className="col-md-3 form-group">
                <Select 
                   options={tipoSocioSelect}
                   placeholder="Selecione... Tipo de sócio" 
                   defaultValue={tipoSocioSelect.find(
                     x => x.value === buscaTipoSocio
                   )}
                   onChange={(e) => { setBuscaTipoSocio(e.value) }}
                   />
              </div>

              <div className="col-md-3 form-group">
                <Select 
                   options={areaAtuacaoSelect}
                   placeholder="Selecione... Área de atuação" 
                   defaultValue={areaAtuacaoSelect.find(
                     x => x.value === buscaAreaAtuacao
                   )}
                   onChange={(e) => { setBuscaAreaAtuacao(e.value) }}
                   />
              </div>

              <div className="col-md-3 form-group">
                <Select 
                  options={regionalSelect}
                  placeholder="Selecione... Regional" 
                  defaultValue={regionalSelect.find(
                    x => x.value === buscaRegional
                  )}
                  onChange={(e) => { setBuscaRegional(e.value) }}
                   />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-right">
                <button className="btn btn-primary" onClick={e => buscar() }><i className="flaticon2-search-1" />Pesquisar</button>
              </div>
            </div>
            <br />
              
          </div>
        </div>
      </div>
      </div>
           
           <br /><br />

                  <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                  
                  
                  <div className="ml-5 mr-5 mt-10" ref={printRef}>
                    <div className="cabecalho">
                  <table>
                    <tr>
                        <td width="100%">
                          <img alt="Logo" src="../../imagem/relatorio.jpg" width="50%"/>
                        </td>
                    </tr>
                  </table>
                  <br />
                  <h3 className="text-center">Relatório de Etiquetas de Revista</h3>
                  <br />
                </div>

                <div className='row'>
                    <div className='col-md-12 text-right'>
                      <h3>Quantidade de registros: <b>{qtdRegistro}</b></h3>
                    </div>
                </div>
                
                <table className="table table-striped table-bordered">
                  <thead>
                      <tr>
                          <th className="text-center">Nome</th>
                          <th className="text-center">Estado</th>
                          <th className="text-center">Departamento</th>
                          <th className="text-center" width="10%">Revista</th>
                      </tr>
                  </thead>
                  <tbody>
                    { relatorio.map( (item ) => { 
                      
                      return (
                        <>
                          <tr>
                            <td>{item.dp_nome_rsociao}</td>
                            <td className="text-center">{item.uf}</td>
                            <td>{item.departamento}</td>
                            <td className="text-center">
                                { item.revista == 'R1' && <label className="label label-info label-inline"><b>Revista 1</b></label> }
                                { item.revista == 'R2' && <label className="label label-success label-inline"><b>Revista 2</b></label> }
                                { item.revista == 'R3' && <label className="label label-warning label-inline"><b>Revista 3</b></label> }
                                { item.revista == 'R4' && <label className="label label-danger label-inline"><b>Revista 4</b></label> }
                                { item.revista == 'R5' && <label className="label label-secondary label-inline"><b>Revista 5</b></label> }
                            </td>
                          </tr> 
                        </>
                      )
                    })
                    }                                                                                          
                  </tbody>
                </table>
              </div>
              
                  </LoadingOverlay>

      
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
  
        </>
    );

}

export default withRouter(Retiquetas);                  